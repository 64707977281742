var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            { staticStyle: { "max-width": "1600px" }, attrs: { fluid: "" } },
            [
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" Search "),
                        _c("span", { staticStyle: { "padding-left": "2px" } }, [
                          _vm._v("(" + _vm._s(_vm.totalRecord) + ")"),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { type: "text", label: "Name" },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllFactoringCompanies()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.businessName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "businessName",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.businessName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { type: "text", label: "City" },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllFactoringCompanies()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "city", $$v)
                                      },
                                      expression: "searchParam.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { type: "text", label: "State" },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllFactoringCompanies()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.state,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "state", $$v)
                                      },
                                      expression: "searchParam.state",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { type: "text", label: "Email" },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllFactoringCompanies()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "email", $$v)
                                      },
                                      expression: "searchParam.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { type: "text", label: "Phone" },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllFactoringCompanies()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "phone", $$v)
                                      },
                                      expression: "searchParam.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs",
                                  attrs: { cols: "12", md: "3", sm: "4" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "blue white--text dark-3",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchAllFactoringCompanies()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-search-web "),
                                      ]),
                                      _vm._v(" Filter "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetSearch()
                                        },
                                      },
                                    },
                                    [_vm._v(" Reset ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            {
              staticClass: "pt-0 data-container",
              attrs: { id: "tblFactoring", fluid: "" },
            },
            [
              _c("v-data-table", {
                staticClass: "elevation-1 data-table",
                attrs: {
                  id: "tblData",
                  headers: _vm.headers,
                  items: _vm.factoringCompanies,
                  "calculate-widths": "",
                  "hide-default-footer": "",
                  loading: _vm.loadingIcon,
                  "fixed-header": "",
                  "items-per-page": _vm.pageSize,
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.businessName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "name-link",
                            attrs: {
                              to: {
                                name: "Factoring Detail",
                                params: { id: item.id },
                              },
                              target: "_blank",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.businessName) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "offset-x": "",
                              absolute: "",
                              "nudge-width": 250,
                            },
                            on: {
                              input: function ($event) {
                                !$event &&
                                  (_vm.replacementSourceFactoringComapny =
                                    _vm.replacementTargetFactoringCompany =
                                      null)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            on: {
                                              click: function ($event) {
                                                return void 0
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [_vm._v(" mdi-file-replace-outline ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "auto-complete-container" },
                              [
                                _c("b", [
                                  _vm._v(
                                    "Select replacement factoring company"
                                  ),
                                ]),
                                _c("factoring-auto-complete", {
                                  attrs: {
                                    factoringCompanyName:
                                      (
                                        _vm.replacementTargetFactoringCompany ||
                                        {}
                                      ).businessName || "",
                                  },
                                  on: {
                                    factoringCompanySelect: function ($event) {
                                      return _vm.selectFactoringCompany(
                                        item,
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("div", [
                                  _vm._v(
                                    " Source: " +
                                      _vm._s(item.businessName) +
                                      " - " +
                                      _vm._s(item.addressLine1) +
                                      " "
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    " Target: " +
                                      _vm._s(
                                        !_vm.replacementTargetFactoringCompany
                                          ? "Not selected"
                                          : `${_vm.replacementTargetFactoringCompany.businessName} - ${_vm.replacementTargetFactoringCompany.addressLine1}`
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "blue white--text dark-3",
                                    attrs: {
                                      small: "",
                                      disabled:
                                        !_vm.replacementTargetFactoringCompany,
                                      loading: _vm.isReplacing,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.replaceFactoringCompany()
                                      },
                                    },
                                  },
                                  [_vm._v(" Replace ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("v-page", {
                          staticClass: "pt-3 pr-3 pb-3",
                          attrs: {
                            "total-row": _vm.totalRecord,
                            "page-size-menu": [10, 20, 50, 100],
                          },
                          on: { "page-change": _vm.readDataFromAPI },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }